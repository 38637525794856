import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {

  constructor(private location: Location) { }

  ngOnInit(): void {
  }

  resetComponent(){
    // this.location.replaceState(this.location.path());
    // window.location.reload();
  }

}
