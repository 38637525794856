import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LookupComponent } from './lookup/lookup.component';
import { LandingComponent } from './landing/landing.component';
import { ScooperDashboardComponent } from './scooper-dashboard/scooper-dashboard.component';
import { PricingComponent } from './pricing/pricing.component';
import { FeaturesComponent } from './features/features.component';

const routes: Routes = [
  { path: '', component: LandingComponent },
  {path:'lookup', component:LookupComponent ,pathMatch: 'full'},
  {path:'crew', component:ScooperDashboardComponent, pathMatch: 'full'},
  {path:'pricing', component:PricingComponent, pathMatch: 'full'},
  {path:'features', component:FeaturesComponent, pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
