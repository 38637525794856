<div class="scooper-dash">
  <div class="container">
    <app-loader *ngIf="isLoading"></app-loader>
    <div
      *ngIf="showToastrError"
      [ngStyle]="{
        display: showToastrError ? 'block' : 'none',
        visibility: showToastrError ? 'visible' : 'hidden',
        opacity: showToastrError ? 1 : 0,
        transition: 'opacity 0.3s'
      }"
    >
      <div class="toastrr-err">
        <div class="row">
          <div class="col-lg-12">
            <div class="d-flex justify-content-center">
              <div
                class="alert alert-danger alert-dismissible fade show"
                role="alert"
              >
                {{ toastrErrorMessage }}
                <button
                  type="button"
                  class="close"
                  data-dismiss="alert"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="showToastr"
      [ngStyle]="{
        display: showToastr ? 'block' : 'none',
        visibility: showToastr ? 'visible' : 'hidden',
        opacity: showToastr ? 1 : 0,
        transition: 'opacity 0.3s'
      }"
    >
      <div class="toastrr-successs">
        <div class="row">
          <div class="col-lg-12">
            <div class="d-flex justify-content-center">
              <div
                class="alert alert-success alert-dismissible fade show"
                role="alert"
              >
                {{ toastrSuccessMessage }}
                <button
                  type="button"
                  class="close"
                  data-dismiss="alert"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />
    <div *ngIf="!hideLogin" class="login-container">
      <div class="row">
        <div class="col-lg-12">
          <div class="d-flex justify-content-center" style="margin-bottom:50px;">
            <div class="card bg-dark card-crew-form">
              <img
                class="card-img-top"
                src="../../assets/img/blue-sky-cloud-logo.png"
                alt="Card image cap"
                class="crew-card-img"
              />
              <div class="card-body">
                <div class="form-crew">
                  <div class="form-group">
                    <label for="exampleInputEmail1">Username</label>
                    <input
                      type="email"
                      class="form-control bg-dark"
                      style="color: #fff"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Username"
                      [(ngModel)]="username"
                    />
                  </div>
                  <div class="form-group">
                    <label for="exampleInputPassword1">Password</label>
                    <input
                      type="password"
                      class="form-control bg-dark"
                      style="color: #fff"
                      id="exampleInputPassword1"
                      placeholder="Password"
                      [(ngModel)]="password"
                    />
                  </div>
                  <button
                    class="btn btn-outline-primary"
                    (click)="onLoginCrew()"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="hideLogin" class="login-success-theme">
      <div class="row" *ngIf="isLoginSuccess">
        <div class="col-lg-12">
          <div class="card bg-dark" style="margin-bottom:50px;">
            <div class="card-body">
              <h5 class="card-title">Welcome {{ userEmail }}</h5>
              <div class="apartmentSubmitForm">
                <div class="form-group">
                  <label for="exampleFormControlSelect1"
                    >Apartment Serviced</label
                  >
                  <select
                    [(ngModel)]="selectedApartment"
                    class="form-control bg-dark"
                    id="exampleFormControlSelect1"
                    style="color: #fff"
                    required
                  >
                    <option class="bg-dark" style="color: #fff" value="">
                      -- Select an apartment --
                    </option>
                    <option
                      class="bg-dark"
                      style="color: #fff"
                      *ngFor="let apartment of categorizedData | keyvalue"
                      [value]="apartment.key"
                    >
                      {{ apartment.key }}
                    </option>
                  </select>
                </div>
                <div class="form-group">
                  <label for="exampleFormControlInput1">Weight</label>
                  <input
                    type="weight"
                    class="form-control bg-dark"
                    id="exampleFormControlInput1"
                    placeholder="20"
                    style="color: #fff"
                    [(ngModel)]="selectedWeight"
                    required
                  />
                </div>
                <div class="form-group">
                  <label>Date</label>
                  <div class="input-group date" data-provide="datepicker">
                    <input
                      style="color: #fff"
                      type="date"
                      class="form-control bg-dark"
                      [(ngModel)]="selectedDate"
                      required
                    />
                    <div class="input-group-addon">
                      <span class="glyphicon glyphicon-th"></span>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label>Hot Spots</label>
                  <div class="input-group">
                    <div class="">
                      <input
                        type="text"
                        #hotspotInput
                        class="bg-dark form-control"
                        style="color: #fff"
                        required
                      />
                      <button
                        (click)="addHotspot(hotspotInput.value, hotspotInput)"
                        class="btn btn-success"
                      >
                        Add
                      </button>
                      <ul>
                        <ul>
                          <li
                            *ngFor="let hotspot of hotSpotsArray; let i = index"
                          >
                            {{ hotspot }}
                            <button
                              type="button"
                              class="btn btn-danger"
                              (click)="removeHotspot(i)"
                            >
                              Delete
                            </button>
                          </li>
                        </ul>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label>Notes</label>
                  <textarea
                    class="form-control bg-dark"
                    id="exampleFormControlTextarea1"
                    style="color: #fff"
                    rows="3"
                    [(ngModel)]="additionalNotes"
                  ></textarea>
                </div>
                <button
                  type="button"
                  class="btn btn-primary"
                  (click)="onSubmitForm()"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
