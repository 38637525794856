<div class="position-relative overflow-hidden p-3 p-md-5 m-md-3 text-center">
    <div class="col-md-5 p-lg-5 mx-auto my-5">
      <h1 class="display-4 font-weight-normal">We Solve Poop Problems</h1>
      <p class="lead font-weight-normal">Cut your swear jar in half by never stepping in poop again.</p>
      <a class="btn btn-outline-secondary" href="#">Coming soon</a>
    </div>
    <div class="row">
        <div class="col-md-4">
            <div class="card bg-dark">
                <div class=" card-img-top ">
                    <i class="fa fa-bar-chart" style="font-size:50px; color:#1ceca7"></i>
                </div>
                <!-- <img class="card-img-top" src="..." alt="Card image cap"> -->
                <div class="card-body">
                  <p class="card-text" style="color:#fff;">We provide complete insight on properties.</p>
                </div>
              </div>
        </div>
        <div class="col-md-4">
            <div class="card bg-dark">
                <div class=" card-img-top ">
                    <i class="fa fa-dollar" style="font-size:50px; color:#1ceca7"></i>
                </div>
                <!-- <img class="card-img-top" src="..." alt="Card image cap"> -->
                <div class="card-body">
                  <p class="card-text" style="color:#fff;">Competitive Pricing</p>
                </div>
              </div>
        </div>
        
        <div class="col-md-4">
            <div class="card bg-dark">
                <div class=" card-img-top ">
                    <i class="fa fa-smile-o" style="font-size:50px; color:#1ceca7"></i>
                </div>
                <!-- <img class="card-img-top" src="..." alt="Card image cap"> -->
                <div class="card-body">
                  <p class="card-text" style="color:#fff;">Superior communication.</p>
                </div>
              </div>
        </div>
    </div>
  </div>
