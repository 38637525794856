import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  constructor(private http:HttpClient) {  }

  getPropertyData(): Observable<any> {
    const url = `https://scoopy2-b72bf-default-rtdb.firebaseio.com/datapull.json`;
    return this.http.get<any>(url);
  }
  getPropertyCodes(): Observable<any> {
    const url = `https://scoopy2-b72bf-default-rtdb.firebaseio.com/datapull.json`;
    return this.http.get<any>(url);
  }
  getUserCodes(): Observable<any> {
    const url = `https://scoopy2-b72bf-default-rtdb.firebaseio.com/datapull/scoopers.json`;
    return this.http.get<any>(url);
  }
  getScooperEmployees(): Observable<any> {
    const url = `https://scoopy2-b72bf-default-rtdb.firebaseio.com/scoopers.json`
    return this.http.get<any>(url);
  }
  pushDataToScooperInput(data: any): Observable<any> {
    const url = `https://scoopy2-b72bf-default-rtdb.firebaseio.com/scooper_input.json`;
    return this.http.post<any>(url, data);
  }
  getDataFromScooperInput():Observable<any> {
    const url = `https://scoopy2-b72bf-default-rtdb.firebaseio.com/scooper_input.json`
    return this.http.get<any>(url);
  }
  
  
}
