import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../global.service';
// import { Example } from 'src/assets/classes/example';
import { Chart } from 'chart.js';
import { DatePipe } from '@angular/common';
type CategorizedItem = {
  'Apartment Complex': string;
  DATE: string;
  LBS: number;
};

interface CategorizedData {
  [complexName: string]: object[];
}
interface DataStructure {
  ['Apartment Complex']: string;
  DATE: string;
  LBS: number;
}

@Component({
  selector: 'app-lookup',
  templateUrl: './lookup.component.html',
  styleUrls: ['./lookup.component.css'],
})
export class LookupComponent implements OnInit {
  showLoader: boolean = false;
  showError: boolean = false;
  hideSearch: boolean = false;
  errorMessage: string = '';
  propertyName: string = '';
  propertyToShow: string = '';
  categorizedData: CategorizedData = {};
  categorizedDataSorted: CategorizedData = {};
  dataForSelectedProperty = {};

  constructor(
    private globalService: GlobalService,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    console.log('Getting apartment Data...');
    this.showLoader = true;
    this.globalService.getUserCodes().subscribe((data) => {
      console.log('Getting user codes..');
      console.log(data);
    });
    this.globalService.getPropertyData().subscribe(
      (data) => {
        // console.log(data);
        let weights = data.weights;
        for (let item of weights) {
          // console.log(item)
          let complexName = item['Apartment Complex'];
          if (!this.categorizedData[complexName]) {
            this.categorizedData[complexName] = [];
          }
          this.categorizedData[complexName].push(item);
        }
        console.log('categorized data below');
        console.log(this.categorizedData);
        this.showLoader = false;
      },
      (error) => {
        this.showLoader = false;
        console.error(error);
      }
    );

    this.globalService.getDataFromScooperInput().subscribe((data) => {
      console.log('data from user inputs');
      console.log(data);
      for (let id in data) {
        if (data.hasOwnProperty(id)) {
          let value = data[id];
          console.log('value...');
          console.log(value.apartment);
    
          let apartment = value.apartment;
          console.log('apartment...');
          console.log(apartment);
          let weights = value.weight;
          console.log('weight..');
          console.log(weights);
    
          let weightsInt = parseInt(value.weight);
          let pipedDate = this.datePipe.transform(value.date, 'M/dd/yyyy');
          let dateFormatted = new Date(value.date).toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
          });
          let dataStructureUserInputs = {
            ['Apartment Complex']: value.apartment,
            DATE: pipedDate,
            LBS: weightsInt,
          };
          if (!this.categorizedData[apartment]) {
            this.categorizedData[apartment] = [];
          }
          // Push the new entry to the end of the array for the appropriate apartment complex
          this.categorizedData[apartment].push(dataStructureUserInputs);
        }
      }
      console.log(this.categorizedData);
    });
    
    console.log("categorized data after all ..");
    console.log(this.categorizedData)
  }

  onSubmit(uniqueCode: string) {
    console.log(this.categorizedData);
    this.showLoader = true;
    this.showError = false;
    if (uniqueCode !== '') {
      this.showLoader = true;
      this.globalService.getPropertyCodes().subscribe(
        (data) => {
          let codes = data.uniqueCodes;
          // loop through response
          let foundMatch = false;
          for (let code in codes) {
            if (code == uniqueCode) {
              console.log('Found a match for code: ' + code);
              console.log('Property name in data: ' + codes[code]);
              this.propertyToShow = codes[code];
              console.log('apartment to show is --> ' + this.propertyToShow);
              foundMatch = true;
              // Build out property data to display
              console.log(this.categorizedData);
              for (let property in this.categorizedData) {
                if (property == this.propertyToShow) {
                  this.dataForSelectedProperty = this.categorizedData[property];
                  console.log(this.dataForSelectedProperty);
                  // Do something with the data for the selected property
                  this.onIsDashboardInit(true);
                  break;
                } else {
                  console.log('property did not match');
                  console.log(property);
                  console.log(this.propertyToShow);
                }
              }
              this.propertyName = this.propertyToShow;
              this.hideSearch = true;
              break;
            }
          }
          if (!foundMatch) {
            // Make call for search users

            this.onIsDashboardInit(false);
            console.log('Code did not match.');
            console.log(codes);
            console.log(uniqueCode);
          }

          this.showLoader = false;
        },
        (error) => {
          this.showLoader = false;
          console.error(error);
        }
      );
    } else {
      this.showLoader = false;
      this.showError = true;
      this.errorMessage = 'Opps Something went wrong';
    }
  }
  onIsDashboardInit(isDashboardInit: boolean) {
    console.log(this.onIsDashboardInit);
    // Handle the emitted value
    console.log('Dashboard is initialized:', isDashboardInit);
  }
}
