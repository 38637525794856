<div class="blue-background">
  <div class="jumbotron blue-background">
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-6 order-lg-1">
          <img
            src="../../assets/img/blue-sky-cloud-logo.png"
            alt=""
            class="img-fluid jumbo-img"
          />
        </div>
        <div class="col-12 col-lg-6 order-lg-2">
          <h1 class="display-4 jum-header mb-0">Scoopy Doo Crew</h1>
          <p class="lead font-weight-normal biz-saying">"We Solve Poop Problems"</p>
          <hr class="my-4" />
          <div class="d-flex justify-content-center">
            <p class="mb-0 info-say">Servicing around Charlotte NC</p>
          </div>
          <br>
          <div class="d-flex justify-content-center button-learn-mor-div">
            <a class=" bg-dark btn btn-outline-primary btn-lg learn-more-button" href="#" role="button"
              >Learn more</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
