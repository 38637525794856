import { Component, ViewChild } from '@angular/core';
import { single } from '../../../assets/classes/example';
import { Color } from '@swimlane/ngx-charts'


@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: [ './line-chart.component.css' ]
})
export class LineChartComponent {
  single: any = [];
  view: [number, number] = [100, 300];
  
  // options
  showLegend = true;
  showXAxis = true;
  showYAxis = true;
  showXAxisLabel = true;
  showYAxisLabel = true;
  xAxisLabel = 'Country';
  yAxisLabel = 'Population';

  colorScheme = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
  };

  gradient = false;

  constructor() {
    Object.assign(this, { single });
  }

  onSelect(event: Event) {
    console.log(event);
  }
}

  
