<nav class="navbar navbar-expand-lg navbar-light bg-dark">
    <img src="../assets/img/collar-copy.png" width="50px" height="50px" routerLink="/"/>
    <a class="navbar-brand nav-brand-name" style="font-family: 'Acme', sans-serif;" routerLink="/">Scoopy Doo Crew</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarText">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item">
          <a class="nav-link nav-items-on-bar" style="color:#fff;font-family: 'Montserrat', sans-serif;" routerLink="/features">Features</a>
        </li>
        <li class="nav-item">
          <a class="nav-link nav-items-on-bar" style="color:#fff;font-family: 'Montserrat', sans-serif;" routerLink="/pricing">Services</a>
        </li>
        <li class="nav-item">
          <a class="nav-link nav-items-on-bar" style="color:#fff;font-family: 'Montserrat', sans-serif;" routerLink="/crew">Crew</a>
        </li>
      </ul>
      <span class="navbar-text">
        <button type="button" class="btn btn-outline-primary btn-lrg" style="color:#fff;font-family: 'Montserrat', sans-serif;" (click)="resetComponent()" routerLink="/lookup">Property Lookup</button>
      </span>
    </div>
  </nav>
  <footer class="bg-dark">
    <p>&copy; ScoopyDooCrew LLC. All Rights Reserved</p>
  </footer>