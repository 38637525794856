import { Component, OnInit, ElementRef, ViewChild} from '@angular/core';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { GlobalService } from '../global.service';
import firebase from 'firebase/compat/app';
import { initializeApp } from 'firebase/app';
import { environment } from '../../environments/environment';
import * as L from 'leaflet';


interface Alert {
  type: string;
  message: string;
}
interface CategorizedData {
  [complexName: string]: object[];
}
interface CustomTileLayerOptions extends L.TileLayerOptions {
  variant: string;
  apikey: string;
}

@Component({
  selector: 'app-scooper-dashboard',
  templateUrl: './scooper-dashboard.component.html',
  styleUrls: ['./scooper-dashboard.component.css'],
})
export class ScooperDashboardComponent implements OnInit {
  myForm!: FormGroup;
  map!: L.Map;
  marker!: L.Marker;
  @ViewChild('toastr') toastrSuccess!: ElementRef<any>;
  toastrSuccessTimer: boolean = false;
  toastrSuccessMessage: string = '';
  toastrErrorMessage: string = '';
  additionalNotes: string = '';
  title = 'ng-bootstrap-datepicker';
  selectedApartment: string = '';
  isLoading: boolean = false;
  username: string = '';
  password: string = '';
  hideLogin: boolean = false;
  isLoginError: boolean = false;
  isLoginSuccess: boolean = false;
  errorMessage: string = '';
  userEmail: any = null;
  showToastr: boolean = false;
  showToastrError:boolean = false;
  selectedWeight: number = 0;
  selectedDate: string = '';
  hotSpotsArray: string[] = [];
  categorizedData: CategorizedData = {};

  constructor(private globalService: GlobalService) {

  }

  ngOnInit(): void {
    initializeApp(environment.firebaseConfig);
  }

  onLoginCrew() {
    this.hideLogin = true;
    this.isLoading = true;
    this.resetValues();
    const auth = getAuth();
    console.log('Submit login');
    signInWithEmailAndPassword(auth, this.username, this.password)
      .then((userCredential) => {
        console.log('user creds..');
        console.log(userCredential);
        // User is signed in.
        let user = userCredential.user;
        console.log(`Logged in as ${user.email}`);
        // this.toastr.success(`Logged in as ${user.email}`)
        this.username = '';
        this.password = '';
        this.userEmail = user.email;
        this.isLoginSuccess = true;
        if (this.isLoginSuccess == true) {
          this.toastrSuccessMessage = `Welcome ${this.userEmail}`
          setTimeout(() => {
            console.log('creating map');
            this.createMap();
          }, 5000);
          // this.setToastrSuccess();
          this.toggleToastrVisibility();
          // pull scooper employee data
          // this.globalService.getScooperEmployees().subscribe(
          //   (data) => {
          //     console.log('scooper data..');
          //     console.log(data);
          //     this.isLoading = false;
          //   },
          //   (error) => {
          //     console.error(error);
          //   }
          // );
          this.globalService.getPropertyData().subscribe(
            (data) => {
              console.log(data);
              let weights = data.weights;
              for (let item of weights) {
                // console.log(item)
                let complexName = item['Apartment Complex'];
                if (!this.categorizedData[complexName]) {
                  this.categorizedData[complexName] = [];
                }
                this.categorizedData[complexName].push(item);
              }
              console.log('categorized data..');
              console.log(this.categorizedData);
              console.log('.complexName');
              console.log(this.categorizedData.complexName);
              this.isLoading = false;
            },
            (error) => {
              console.error(error);
            }
          );
        } else {
          console.log('login was not successful');
          this.isLoading = false;
        }
      })
      .catch((error) => {
        // Handle errors here.
        this.isLoginError = true;
        let errorCode = error.code;
        this.toastrErrorMessage = error.message;
        this.errorMessage = error.message;
        console.error(`Error: ${errorCode} - ${this.errorMessage}`);
        this.hideLogin = false;
        this.isLoading = false;
        this.toggleToastrVisibilityError()
        // this.toastr.error(`Error: ${errorCode} - ${errorMessage}`)
      });
  }
  onSubmitForm(){
    console.log(this.validateInputValues(this.selectedApartment, this.selectedWeight, this.selectedDate, this.hotSpotsArray))
    if(this.validateInputValues(this.selectedApartment, this.selectedWeight, this.selectedDate, this.hotSpotsArray)){
      this.isLoginSuccess = false;
      this.isLoading = true;
    const data = {
      scooper: this.userEmail,
      apartment: this.selectedApartment,
      weight: this.selectedWeight,
      date: this.selectedDate,
      hotSpots: this.hotSpotsArray,
      notes: this.additionalNotes
    };
    this.globalService.pushDataToScooperInput(data).subscribe(response => {
      console.log("submitted successfully")
      console.log(response)
      if(response){
        this.toastrSuccessMessage = `Successfully Sent!`
        this.toggleToastrVisibility();
        this.resetForm();
        this.isLoginSuccess = true;
        this.isLoading = false;
      }
    }, error => {
      console.log("error putting")
      console.log(error)
    })
    } else {
      // this.toastrErrorMessage = 'Please check form'
      this.toggleToastrVisibilityError();
    }
    

  }

  resetForm(){
    this.selectedApartment = '';
    this.selectedWeight = 0;
    this.selectedDate = '';
    this.hotSpotsArray = [];
    this.additionalNotes = '';
  }

  createMap() {
  }
  addHotspot(hotspotValue: string, hotspotInput: HTMLInputElement) {
    if (hotspotValue.trim()) {
      this.hotSpotsArray.push(hotspotValue.trim());
      hotspotInput.value = ''; // Reset the input field value
    }
  }
  
  removeHotspot(index: number) {
    this.hotSpotsArray.splice(index, 1);
  }
  
  
  get hotSpots() {
    return this.myForm.get('hotSpots') as FormArray;
  }
  

  setToastrSuccess() {
    if (this.toastrSuccess) {
      setTimeout(() => {
        // this.toastrSuccessTimer = ;
      }, 5000); // hide toastr after 5 seconds (5000 ms)
    }
  }
  toggleToastrVisibility() {
    this.showToastr = !this.showToastr;
    setTimeout(() => {
      this.showToastr = !this.showToastr;
    },1000)
  }
  toggleToastrVisibilityError(){
    this.showToastrError = !this.showToastrError
    setTimeout(() => {
      this.showToastrError = !this.showToastrError;
    },1000)
  }
  validateInputValues(selectedApartment:string, selectedWeight:number, selectedDate:string, hotSpotsArray:any) {
    let isValid = true;
    const weightRegex = /^[-]?\d*\.?\d+$/; // Regex for any integer, including negative integers
  
    if (!selectedApartment) {
      isValid = false;
      console.error('Please select an apartment serviced.');
      this.toastrErrorMessage = 'Please select an apartment serviced.';
    }
  
    if (!selectedWeight || !weightRegex.test(selectedWeight.toString())) {
      isValid = false;
      console.error('Please enter a valid weight (integer).');
      this.toastrErrorMessage = 'Please enter a valid weight (integer).';
    }
  
    if (!selectedDate) {
      isValid = false;
      console.error('Please select a date.');
      this.toastrErrorMessage = 'Please select a date.';
    }
  
    if (hotSpotsArray.length === 0) {
      isValid = false;
      console.error('Please add at least one hotspot.');
      this.toastrErrorMessage = 'Please add at least one hotspot.';
    }
  
    return isValid;
  }
  
  resetValues() {
    this.isLoginSuccess = false;
    this.isLoginError = false;
  }
}
