<div class="lookup-body"style="">
  <div class="container">
    <div class="row">
        <div class="col-lg-12">
            <div class="d-flex justify-content-center search-comp">
                <app-loader *ngIf="showLoader" class="loader-marg"></app-loader>
                <!-- <app-error [errorMessage]="errorMessage" *ngIf="showError"></app-error> -->
                <div *ngIf="!hideSearch">
                    <div class="card bg-dark card-whole"  *ngIf="!showLoader">
                        <img src="../../assets/img/collar-copy.png" class="card-img-top" alt="...">
                        <div class="card-body">
                          <div class="form-group">
                            <p class="card-text" style="color:#fff; font-family: 'Montserrat', sans-serif;">Please enter your unique code.</p>
                            <div class="input-group mb-3">
                              <div class="input-group-prepend">
                                <span class="input-group-text bg-dark" style="color:#fff;" id="inputGroup-sizing-default">Code</span>
                              </div>
                              <input #box type="text" class="form-control bg-dark"style="color:#fff;" aria-label="" aria-describedby="">
                              <button type="button" class="btn btn-outline-primary btn-lrg" style="color:#fff;font-family: 'Montserrat', sans-serif; margin-left:5px;" (click)="onSubmit(box.value)">Search</button>
                            </div>
                          </div>
                        </div>
                      </div>
                </div>
                
            </div>
        </div>
    </div>
</div>
<div *ngIf="hideSearch">
  <div>
      <app-dashboard (isDashboardInitEvent)="onIsDashboardInit($event)" [propertyData]="dataForSelectedProperty" [propertyName]="propertyName"></app-dashboard>
      <app-scooper-dashboard *ngIf="!onIsDashboardInit"></app-scooper-dashboard>
  </div>
</div>
</div>

