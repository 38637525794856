import { NgModule, CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatGridListModule } from '@angular/material/grid-list';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LandingComponent } from './landing/landing.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LookupComponent } from './lookup/lookup.component';
import { NavigationComponent } from './navigation/navigation.component';
import { LoaderComponent } from './loader/loader.component';
import { ErrorComponent } from './error/error.component';
import { HttpClientModule } from '@angular/common/http';
import { GlobalService } from './global.service';
import { NgxChartsModule } from '@swimlane/ngx-charts'
import {LineChartComponent } from './charts/line-chart/line-chart.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ScooperDashboardComponent } from './scooper-dashboard/scooper-dashboard.component'
import { KeysPipe } from './keys.pipe';
import { PricingComponent } from './pricing/pricing.component';
import { FeaturesComponent } from './features/features.component';



@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    LookupComponent,
    NavigationComponent,
    LoaderComponent,
    ErrorComponent,
    LineChartComponent,
    DashboardComponent,
    ScooperDashboardComponent,
    KeysPipe,
    PricingComponent,
    FeaturesComponent
  ],
  imports: [
    ReactiveFormsModule,
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSlideToggleModule,
    MatGridListModule,
    HttpClientModule,
    NgxChartsModule,
  ],
  providers: [GlobalService, DatePipe],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { 
  ngOnInit() {
  }
 }
