import { Component, OnInit, Input,Output,EventEmitter } from '@angular/core';
import { Chart, registerables } from 'chart.js';
interface YearData {
  [year: string]: number;
}
interface PropertyDataItem {
  DATE: string;
  LBS: number;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
  @Input() propertyData: any;
  @Input() propertyName: any;
  @Output() isDashboardInitEvent = new EventEmitter<boolean>()
  isDashboardInit: boolean = false;
  isDashboard: boolean = true;
  isHistory: boolean = false;
  isPhoto: boolean = false;
  isNote: boolean = false;
  imgSrc: string = '';
  currentYearHistory: number = 0;
  lbsSum: number = 0;
  lbsAvg: number = 0;
  lbsMax: number = 0;
  lbsMin: number = 0;
  lbsMaxMonth: object = {};
  lbsMinMonth: object = {};
  lbsMinMonthValue: number = 0;
  lbsMinMonthName: string = 'No Data';
  lbsMaxMonthValue: number = 0;
  lbsMaxMonthName: string = 'No Data';
  visitsCurrentYear: number = 0;
  highestVisitDate: string = '';
  lowestVisitDate: string = '';
  lightestItemDate: string = '';
  heaviestItemDate: string = '';
  lightestItemWeight: number = 0;
  heaviestItemWeight: number = 0;

  constructor() {
    Chart.register(...registerables);
  }

  ngOnInit(): void {
    console.log('From dashboard component ' + this.propertyName);
    this.imgSrc = `../../assets/img/properties/${this.propertyName}.png`;
    if (this.propertyData) {
      this.isDashboard = true;
      this.isDashboardInit = true;
      this.emitIsDashboardInit(this.isDashboardInit);
      setTimeout(() => {
        console.log('firing up charts');
        this.generateLineChart();
        this.generateDonutChart();
        this.generateAverage();
        this.generateBarChart();
      }, 30);
    } else {
      console.log('data was not recieved');
    }
    
  }
  emitIsDashboardInit(isDashboardInit: boolean) {
    this.isDashboardInitEvent.emit(isDashboardInit);
  }
  generateLineChart() {
    this.propertyData.sort((a:PropertyDataItem, b:PropertyDataItem) => new Date(a.DATE).getTime() - new Date(b.DATE).getTime());
    const data = {
      labels: this.propertyData.map((item: any) => item.DATE),
      datasets: [
        {
          label: this.propertyName,
          data: this.propertyData.map((item: any) => item.LBS),
          borderColor: this.getRandomColor(),
          fill: false,
        },
      ],
    };

    const options = {
      responsive: true,
      maintainAspectRatio: true,
      plugins: {
        legend: {
          labels: {
            color: '#fff', // Change font color here
          },
        },
      },
    };

    const chart = new Chart('lineChart', {
      type: 'line',
      data: data,
      options: options,
    });
    console.log('line chart log')
    console.log(chart)
  }
  generateLineChartCurrentYear() {
    const currentYear = new Date().getFullYear();
    const currentYearData = this.propertyData.filter((item: any) => new Date(item.DATE).getFullYear() === currentYear);
    
    const data = {
      labels: currentYearData.map((item: any) => item.DATE),
      datasets: [
        {
          label: this.propertyName,
          data: currentYearData.map((item: any) => item.LBS),
          borderColor: this.getRandomColor(),
          fill: false,
        },
      ],
    };
  
    const options = {
      responsive: true,
      maintainAspectRatio: true,
      plugins: {
        legend: {
          labels: {
            color: '#fff', // Change font color here
          },
        },
      },
    };
  
    const chart = new Chart('lineChartCurrentYear', {
      type: 'line',
      data: data,
      options: options,
    });
  }
  generateAverage() {
    let lbsArray = this.propertyData.map((item: any) => item.LBS);
    console.log(lbsArray);
    this.lbsSum = Math.ceil(
      lbsArray.reduce((acc: number, curr: number) => acc + curr, 0)
    );
    this.lbsAvg = Math.ceil(this.lbsSum / lbsArray.length);
    this.lbsMax = Math.max(...lbsArray);
    this.lbsMin = Math.min(...lbsArray);
  }
  generateRecentYearAverage() {
    // Get the current year
    let currentYear = new Date().getFullYear();

    // Filter the propertyData array to include only the data for the current year
    let currentYearData = this.propertyData.filter((item: any) => {
      let year = new Date(item.DATE).getFullYear();
      this.currentYearHistory = currentYear;
      return year === currentYear;
    });
    // Create a new array of LBS values for the current year data
    let lbsArray = currentYearData.map((item: any) => item.LBS);
    console.log('current year pounds array');
    console.log(lbsArray.length);
    this.visitsCurrentYear = lbsArray.length;
    if (lbsArray.length == 0) {
      console.log('There is no current year data.');
      this.lbsSum = 0;
      this.lbsAvg = 0;
      this.lbsMinMonthValue = 0;
      this.lbsMaxMonthValue = 0;
      this.lbsMaxMonthName = 'No Data';
      this.lbsMinMonthName = 'No Data';
    } else {
      // Calculate the sum, average, maximum, and minimum of the lbsArray
      this.lbsSum = Math.ceil(
        lbsArray.reduce((acc: any, curr: any) => acc + curr, 0)
      );
      this.lbsAvg = Math.ceil(this.lbsSum / lbsArray.length);

      // Find the index of the max and min values in the lbsArray
      let maxIndex = lbsArray.indexOf(Math.max(...lbsArray));
      let minIndex = lbsArray.indexOf(Math.min(...lbsArray));

      // Get the month associated with the max and min values
      console.log(currentYearData[maxIndex]);
      this.highestVisitDate = currentYearData[maxIndex].DATE;
      this.lowestVisitDate = currentYearData[minIndex].DATE;
      let maxMonth = currentYearData[maxIndex].DATE.split('/')[0];
      let minMonth = currentYearData[minIndex].DATE.split('/')[0];

      // Set the lbsMax and lbsMin properties to objects with value and month properties
      // this.lbsMaxMonth = { value: Math.max(...lbsArray), month: maxMonth };
      // this.lbsMinMonth = { value: Math.min(...lbsArray), month: minMonth };
      maxMonth = parseInt(maxMonth.replace(/"/g, ''));
      minMonth = parseInt(minMonth.replace(/"/g, ''));
      this.lbsMinMonthValue = Math.min(...lbsArray);
      this.lbsMinMonthName = this.getMonthName(minMonth);
      this.lbsMaxMonthValue = Math.max(...lbsArray);
      this.lbsMaxMonthName = this.getMonthName(maxMonth);
    }
  }
  getLightestAndHeaviestVisitDateWithAmounts() {
    // Get the current year
    let currentYear = new Date().getFullYear();
  
    // Filter the propertyData array to include only the data for the current year
    let currentYearData = this.propertyData.filter((item: any) => {
      let year = new Date(item.DATE).getFullYear();
      this.currentYearHistory = currentYear;
      return year === currentYear;
    });
  
    if (currentYearData.length === 0) {
      console.log('There is no current year data.');
      return;
    }
  
    // Get the lightest and heaviest visit dates for the current year
    let lightestItem = currentYearData.reduce((prev: any, current:any) => {
      return prev.LBS < current.LBS ? prev : current;
    });
    this.lightestItemDate = lightestItem.DATE;
    this.lightestItemWeight = lightestItem.LBS;
  
    let heaviestItem = currentYearData.reduce((prev: any, current:any) => {
      return prev.LBS > current.LBS ? prev : current;
    });
    this.heaviestItemDate = heaviestItem.DATE;
    this.heaviestItemWeight = heaviestItem.LBS;
  
    console.log(`Your lightest visit date for the current year is ${lightestItem.DATE} with a weight of ${lightestItem.LBS} and your heaviest visit date for the current year is ${heaviestItem.DATE} with a weight of ${heaviestItem.LBS}.`);
  }
  generateDonutChart() {
    // Group propertyData by year and calculate total for each year
    let dataByYear: YearData = {};
    this.propertyData.forEach((item: any) => {
      let year = new Date(item.DATE).getFullYear().toString();
      if (dataByYear[year]) {
        dataByYear[year] += item.LBS;
      } else {
        dataByYear[year] = item.LBS;
      }
    });

    // Create data for chart using dataByYear
    let labels = Object.keys(dataByYear);
    let data = Object.values(dataByYear);
    console.log('data..');
    console.log(data);
    console.log(labels);
    let backgroundColor = [];
    for (let i = 0; i < labels.length; i++) {
      backgroundColor.push(this.getRandomColor());
    }

    const chartData = {
      labels: labels,
      datasets: [
        {
          label: 'Totals over the years',
          data: data,
          backgroundColor: backgroundColor,
          hoverOffset: 4,
        },
      ],
    };

    const options = {
      responsive: true,
      maintainAspectRatio: true,
      plugins: {
        legend: {
          labels: {
            color: '#fff', // Change font color here
          },
        },
      },
    };

    let chart = new Chart('donutChart', {
      type: 'doughnut',
      data: chartData,
      options: options,
    });
  }
  generateBarChart() {
    // Create object with total amounts for each month
    const dataByMonth = this.propertyData.reduce((acc: any, curr: any) => {
      const month = new Date(curr.DATE).toLocaleString('default', {
        month: 'long',
        year: 'numeric',
      });
      acc[month] = (acc[month] || 0) + curr.LBS;
      return acc;
    }, {});

    // Create array of labels and data based on object keys and values
    const labels = Object.keys(dataByMonth);
    const data = Object.values(dataByMonth);

    const chartData = {
      labels: labels,
      datasets: [
        {
          label: this.propertyName,
          data: data,
          backgroundColor: this.getRandomColor(),
          borderColor: this.getRandomColor(),
          borderWidth: 1,
        },
      ],
    };

    const options = {
      responsive: true,
      maintainAspectRatio: true,
      scales: {
        y: {
          beginAtZero: true,
        },
      },
      plugins: {
        legend: {
          labels: {
            color: '#fff', // Change font color here
          },
        },
      },
    };

    const chart = new Chart('barChart', {
      type: 'bar',
      data: chartData,
      options: options,
    });
  }
  generateBarChartCurrentYear() {
    // Get the current year
    const currentYear = new Date().getFullYear();
  
    // Filter the property data to only include data from the current year
    const dataForCurrentYear = this.propertyData.filter((data: any) => {
      const year = new Date(data.DATE).getFullYear();
      return year === currentYear;
    });
  
    // Create object with total amounts for each month
    const dataByMonth = dataForCurrentYear.reduce((acc: any, curr: any) => {
      const month = new Date(curr.DATE).toLocaleString('default', {
        month: 'long',
        year: 'numeric',
      });
      acc[month] = (acc[month] || 0) + curr.LBS;
      return acc;
    }, {});
  
    // Create array of labels and data based on object keys and values
    const labels = Object.keys(dataByMonth);
    const data = Object.values(dataByMonth);
  
    const chartData = {
      labels: labels,
      datasets: [
        {
          label: this.propertyName,
          data: data,
          backgroundColor: this.getRandomColor(),
          borderColor: this.getRandomColor(),
          borderWidth: 1,
        },
      ],
    };
  
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          beginAtZero: true,
        },
      },
      plugins: {
        legend: {
          labels: {
            color: '#fff', // Change font color here
          },
        },
      },
    };
  
    const chart = new Chart('barChartCurrentYear', {
      type: 'bar',
      data: chartData,
      options: options,
    });
  }
  getRandomColor() {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }
  getMonthName(monthNumber: number): string {
    switch (monthNumber) {
      case 1:
        return 'January';
      case 2:
        return 'February';
      case 3:
        return 'March';
      case 4:
        return 'April';
      case 5:
        return 'May';
      case 6:
        return 'June';
      case 7:
        return 'July';
      case 8:
        return 'August';
      case 9:
        return 'September';
      case 10:
        return 'October';
      case 11:
        return 'November';
      case 12:
        return 'December';
      default:
        return 'Invalid month number';
    }
  }
  // side nav
  navReset() {
    this.isDashboard = false;
    this.isHistory = false;
    this.isPhoto = false;
    this.isNote = false;
    console.log('Dashboard Reset');
  }
  noteDashboard() {
    if (this.propertyData) {
      this.navReset();
      this.isNote = true;
      setTimeout(() => {
        // Chart Generations
      }, 30);
    }
  }
  photoDashboard() {
    if (this.propertyData) {
      this.navReset();
      this.isPhoto = true;
      setTimeout(() => {
        // Chart Generations
      }, 30);
    }
  }

  historyDashboard() {
    if (this.propertyData) {
      this.navReset();
      this.isHistory = true;
      setTimeout(() => {
        this.generateRecentYearAverage();
        this.getLightestAndHeaviestVisitDateWithAmounts();
        this.generateLineChartCurrentYear();
        this.generateBarChartCurrentYear();
        // Chart Generations
      }, 30);
    }
  }

  mainDashboard() {
    if (this.propertyData) {
      if (this.isDashboard == true) {
        console.log('Already on Dashboard');
      } else {
        this.navReset();
        this.isDashboard = true;
        setTimeout(() => {
          console.log('firing up charts');
          this.generateLineChart();
          this.generateDonutChart();
          this.generateAverage();
          this.generateBarChart();
        }, 30);
      }
    } else {
      console.log('data was not recieved');
    }
  }
}
