<div class="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
    <h1 class="display-4 pricing-header">Services</h1>
    <p class="lead">One stop shop for pet waste needs</p>
  </div>
  <hr>
  <div class="container" style="font-family: 'Montserrat', sans-serif;">
    <div class="card-deck mb-3 text-center">
        <div class="card mb-4 shadow-sm bg-dark" style="color:#fff;">
          <div class="card-header">
            <!-- <h4 class="my-0 font-weight-normal">$</h4> -->
          </div>
          <div class="card-body">
            <h1 class="card-title pricing-card-title">Our Tailored Services</h1>
            <ul class="list-unstyled mt-3 mb-4">
              <li>Pet Waste Removal</li>
              <li>Pet Waste Station Trash Removal</li>
              <li>Pet Waste Station Installation, Repair, Maintenance</li>
              <li>Pet Waste Station Bags, Can Liners</li>
            </ul>
            <button type="button" class="btn btn-lg btn-block btn-outline-primary">Contact For Free Quote</button>
          </div>
        </div>
      </div>
    
  </div>
  