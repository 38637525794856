<div class="dashboard-body" style="background-color: #1eb1ee;">
    <div class="sidebar bg-dark">
        <ul class="sidebar-nav">
            <li>
                <a style="font-family: 'Montserrat', sans-serif;">
                  <i>
                    <img src={{imgSrc}} class="img-src-icon-nav"/>
                  </i>
                  {{propertyName}}
                </a>
              </li>
          <li>
            <a class="side-nav-item-class" (click)="mainDashboard()">
              <i class="fa fa-dashboard"></i>
              Dashboard
            </a>
          </li>
          <li>
            <a class="side-nav-item-class" (click)="historyDashboard()">
              <i class="fa fa-history"></i>
              History
            </a>
          </li>
          <li>
            <a class="side-nav-item-class" (click)="photoDashboard()">
              <i class="fa fa-camera"></i>
              Photos
            </a>
          </li>
          <li>
            <a class="side-nav-item-class"(click)="noteDashboard()">
              <i class="fa fa-file-text-o"></i>
              Notes
            </a>
          </li>
        </ul>
      </div>
      <div *ngIf="isNote">
        <div class="container">
          <div class="row">
              <div class="col-lg-12">
                  <h1 class="dash-header">{{propertyName}} Notes</h1>
                  <hr>
              </div>
          </div>
          </div>
      </div>
      <div *ngIf="isPhoto">
        <div class="container">
          <div class="row">
              <div class="col-lg-12">
                  <h1 class="dash-header">{{propertyName}} Photos</h1>
                  <hr>
              </div>
          </div>
          </div>
      </div>
      <div *ngIf="isHistory">
        <div class="container">
          <div class="nav-for-mobile">
            <div class="d-flex justify-content-between">
              <button type="button" class="btn btn-outline-dark">
                <a class="side-nav-item-class" (click)="mainDashboard()">
                  <i class="fa fa-dashboard"></i>
                  Dashboard
                </a>
              </button>
              <button type="button" class="btn btn-outline-dark">
                <a class="side-nav-item-class" (click)="historyDashboard()">
                  <i class="fa fa-history"></i>
                  History
                </a>
              </button>
            </div> 
          </div>         
          <div class="row">
              <div class="col-lg-12">
                  <h1 class="dash-header">{{propertyName}} History {{currentYearHistory}} </h1>
                  <hr>
              </div>
          </div>
          <div class="row">
          </div>
          <div class="row" style="padding-bottom:30px;">
            <div class="col-lg-3" style="padding-bottom:5px;">
              <div class="card text-center bg-dark card-theme-dash">
                <div class="card-body">
                  <h5 class="card-title">Total Collected</h5>
                  <p class="card-text">{{lbsSum}} lbs</p>
                </div>
              </div>
            </div>
            <div class="col-lg-3" style="padding-bottom:5px;">
              <div class="card text-center bg-dark card-theme-dash">
                <div class="card-body">
                  <h5 class="card-title">Lowest Month</h5>
                  <p class="card-text">{{lbsMinMonthName}} for {{lbsMinMonthValue}} lbs</p>
                </div>
              </div>
            </div>
            <div class="col-lg-3" style="padding-bottom:5px;">
              <div class="card text-center bg-dark card-theme-dash">
                <div class="card-body">
                  <h5 class="card-title">Highest Month</h5>
                  <p class="card-text">{{lbsMaxMonthName}} for {{lbsMaxMonthValue}} lbs</p>
                </div>
              </div>
            </div>
            <div class="col-lg-3" style="padding-bottom:5px;">
              <div class="card text-center bg-dark card-theme-dash">
                <div class="card-body">
                  <h5 class="card-title">Total Visits</h5>
                  <p class="card-text">{{visitsCurrentYear}}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <h3 style="font-family: 'Montserrat', sans-serif; text-align: center; font-weight: bold;">Lightest Visit</h3>
              <hr>
              </div>
              <div class="col-lg-6">
                <h3 style="font-family: 'Montserrat', sans-serif; text-align: center; font-weight: bold;">Heaviest Visit</h3>
                <hr>
              </div>
              </div>
              <div class="row">
                <div class="col-lg-3">
                  <ul class="poop-list">
                    <li>{{lowestVisitDate}}</li>
                    <li>{{lightestItemWeight}} lbs</li>
                  </ul>
              </div>
              <div class="col-lg-3">
                <i class="fa fa-thumbs-up" aria-hidden="true" style="font-size: 100px; color:green; text-align: center;"></i>
            </div>
              <div class="col-lg-3">
                <ul class="poop-list">
                  <li>{{heaviestItemDate}}</li>
                  <li>{{heaviestItemWeight}} lbs</li>
                </ul>
              </div>
              <div class="col-lg-3">
                <i class="fa fa-thumbs-down" aria-hidden="true" style="font-size: 100px; color:red; text-align: center;"></i>
              </div>
              </div>
              <div class="currentYearGraphs">
                <div class="row">
                  <div class="col-lg-6" style="padding-bottom:5px;">
                    <div class="card bg-dark card-theme-dash">
                      <div class="card-header">
                        {{currentYearHistory}} Trend
                      </div>
                      <hr>
                      <div class="card-body">
                        <h5 class="card-title"></h5>
                        <div style="text-align: center;">
                          <canvas id="lineChartCurrentYear" style="margin: 0 auto; width: 100%; height: 100%;"></canvas>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="card bg-dark card-theme-dash" style="margin-bottom: 50px;">
                      <div class="card-header">
                        {{currentYearHistory}} Monthly
                      </div>
                      <hr>
                      <div class="card-body">
                        <h5 class="card-title"></h5>
                        <div style="text-align: center;">
                          <canvas id="barChartCurrentYear" style="margin: 0 auto; width: 100%; height: 100%;"></canvas>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
          </div>
      <div class="dashboard" *ngIf="isDashboard">
        <div class="container">
          <div class="nav-for-mobile">
            <div class="d-flex justify-content-between">
              <button type="button" class="btn btn-outline-dark">
                <a class="side-nav-item-class" (click)="mainDashboard()">
                  <i class="fa fa-dashboard"></i>
                  Dashboard
                </a>
              </button>
              <button type="button" class="btn btn-outline-dark">
                <a class="side-nav-item-class" (click)="historyDashboard()">
                  <i class="fa fa-history"></i>
                  History
                </a>
              </button>
            </div> 
          </div> 
          <div class="row">
              <div class="col-lg-12">
                  <h1 class="dash-header">{{propertyName}} Overview</h1>
                  <hr>
              </div>
          </div>
          <div class="top-graphs">
              <div class="row">
                  <div class="col-lg-8">
                    <div style="padding-bottom:5px;">
                      <div class="card bg-dark card-theme-dash">
                        <div class="card-header">
                            {{propertyName}} History
                        </div>
                        <div class="card-body">
                          <h5 class="card-title"></h5>
                          <div style="text-align: center;">
                            <canvas id="lineChart" class="line-chart-dash"></canvas>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                      <div class="card bg-dark card-theme-dash">
                          <div class="card-header">
                              {{propertyName}} Now vs Then
                          </div>
                          <div class="card-body">
                              <h5 class="card-title"></h5>
                              <div style="text-align: center;">
                                <canvas id="donutChart"></canvas>
                              </div>
                            </div>
                        </div>
                  </div>
              </div>
          </div>
          <div class="row" style="text-align: center;">
              <div class="col-lg-3" style="padding-bottom:5px;">
                  <div class="card text-center bg-dark card-theme-dash">
                      <div class="card-body">
                        <h5 class="card-title">Average Collected</h5>
                        <p class="card-text">{{lbsAvg}} lbs</p>
                      </div>
                    </div>
              </div>
              <div class="col-lg-3" style="padding-bottom:5px;">
                  <div class="card text-center bg-dark card-theme-dash">
                      <div class="card-body">
                        <h5 class="card-title">Highest Collected</h5>
                        <p class="card-text">{{lbsMax}} lbs</p>
                      </div>
                    </div>
              </div>
              <div class="col-lg-3" style="padding-bottom:5px;">
                  <div class="card text-center bg-dark card-theme-dash">
                      <div class="card-body">
                        <h5 class="card-title">Lowest Collected</h5>
                        <p class="card-text">{{lbsMin}} lbs</p>
                      </div>
                    </div>
              </div>
              <div class="col-lg-3" style="padding-bottom:5px;">
                  <div class="card text-center bg-dark card-theme-dash">
                      <div class="card-body">
                        <h5 class="card-title">Total Collected</h5>
                        <p class="card-text">{{lbsSum}} lbs</p>
                      </div>
                    </div>
              </div>
          </div>
          <div class="lower-graphs">
              <div class="row">
                  <div class="col-lg-12">
                      <div class="card bg-dark card-theme-dash" style="margin-bottom: 50px;">
                          <div class="card-header">
                              {{propertyName}} Monthly Report
                          </div>
                          <div class="card-body">
                            <h5 class="card-title"></h5>
                            <div style="text-align: center;">
                              <canvas id="barChart"></canvas>
                            </div>
                          </div>
                        </div>
                  </div>
              </div>
              <div class="row">
                <div class="last-bar-chart"></div>
              </div>
          </div>
        </div>
      </div>
    </div>
<!-- barChartCurrentYear -->