import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'keys'
  })
  export class KeysPipe implements PipeTransform {
    transform(value: any, args?: any): any {
        console.log("from keys");
        console.log(Object.keys(value))
      return Object.keys(value);
    }
  }
    